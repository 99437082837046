import {ChangeDetectionStrategy, Component} from '@angular/core'

@Component({
  selector: 'cft-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {}
