import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core'

@Component({
  selector: 'a[cft-nav-quicklink], button[cft-nav-quicklink]',
  templateUrl: './nav-quicklink.component.html',
  styles: [
    `
      :host {
        @apply flex gap-xs;
        @apply px-1.5 py-1;
        @apply rounded-lg;
        @apply cursor-pointer;
        @apply text-president-800;
        @apply items-center;
        @apply font-medium;
      }
      :host(.sm) {
        @apply px-sm py-2xs;
        @apply rounded;
      }
      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) :host {
        @apply text-white;
      }
      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) :host(:hover) {
        @apply bg-white/10;
      }
      :host(.active) {
        @apply bg-vermillon-50;
        @apply border border-vermillon-400;
      }
      :host(.active:hover) {
        @apply border border-vermillon-400;
      }
      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) :host(.active) {
        @apply text-president-800;
      }
      :host(:hover) {
        @apply bg-grey-100;
        @apply border-transparent;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NavQuicklinkComponent {
  @HostBinding('class.sm')
  @Input()
  isSmall = false
}
